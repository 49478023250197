/*global ga*/
import {useEffect} from "react";
import InnerHTML from 'dangerously-set-html-content'

function Page_Main() {

    const html = `
    <script type="application/javascript">(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'10000','properties':{'pixelId':'10167860','userEmail': '<email_address>'}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");</script><script type="application/javascript">window.dotq = window.dotq || [];window.dotq.push({projectId:'10000',properties:{pixelId:'10167860',qstrings:{'et':'custom','ea':'customlp'}}});</script>
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-N94ZCE423V"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-N94ZCE423V');
      
      setTimeout(()=> {
          gtag('event', 'age_gate_complete');
      }, 3000)
    </script>
    <script>
      // 2. This code loads the IFrame Player API code asynchronously.
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      var player;
      function onYouTubeIframeAPIReady() {
        player = new YT.Player('player', {
          height: '390',
          width: '640',
          videoId: 'lTIdi1VupAU',
          playerVars: {
            'playsinline': 1
          },
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
          }
        });
      }
      function onPlayerReady(event) {
        
      }
      var done = false;
      function onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.PLAYING && !done) {
          setTimeout(stopVideo, 6000);
          done = true;
        }
      }
      function stopVideo() {
        player.stopVideo();
      }
    </script>
    `

    useEffect(()=> {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {/*inject tracking script tags*/}
            <InnerHTML html={html} />
        <main>
            <section className="section section-header">
                <div className="section__inner">
                    <img src="./assets/header/header_title.png" className="section-header__title"/>
                    <div className="section-header__stripes">
                        <img src="assets/header/header_bottlestripes.png"/>
                    </div>
                </div>
            </section>

            <section className="section-video">
                <div className="section__inner">
                    <div className="textblock pad4">
                        <h2>Monkey Shoulder Hosts After-Party for First Ever Metaverse Festival in Decentraland
                        </h2>
                        <p>
                            At Monkey Shoulder we rarely pass up an opportunity to mix things up. And so we jumped at the chance to team up with Decentraland and our good friends DJ’s Krafty Kuts and A.Skillz to close out the world’s first-ever multi-day music festival held in the Metaverse. The Monkey Shoulder After-Party hosted in the Monkey Shoulder Hex Stage, is a space where festival-goers can dance to a unique cocktail of electronic funk, breakbeats and hip hop, and grab themselves some limited edition wearable NFT’s while they’re at it! Check out below for more:
                        </p>
                    </div>
                    <a name="Anchorname"></a>
                    <div className="pad2">
                        <div className="embed-container">
                            <div id="player"></div>
                            {/*<iframe src='https://www.youtube.com/watch?v=HyVG9KAHOug?enablejsapi=1&origin=https://monkeyshouldermetaverseparty.com/' frameBorder='0'
                                    allowFullScreen></iframe>*/}

                        </div>
                    </div>
                </div>
            </section>

            <section className="section-djs">
                <div className="section__inner">
                    <div className="pad3">
                        <iframe width="100%" height="120"
                                src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2FMonkeyShoulder%2F"
                                frameBorder="0"></iframe>
                    </div>
                    <div className="pad4">
                        <div className="mixcloud">
                        <p>To hear more Monkey Mixes from A Skillz, Krafty Kuts and more check us out on MixCloud:<a
                            href='https://www.mixcloud.com/MonkeyShoulder' target='_blank'>www.mixcloud.com/MonkeyShoulder</a>   </p>
                        </div>
                    </div>
                    <div className="textblock textblock--title pad3">
                        <h1>THE DJS</h1>
                    </div>
                    <div className="pad1">
                        <div className="image-row">
                            <div className="image-row__image">
                                <div className="image-row__swap">
                                    <img src="assets/dj2.jpg" alt="krafty kuts"/>
                                    <img src="assets/DJ_Avatars.psdArtboard 1 copy.jpg" alt="krafty kuts"/>
                                </div>
                                <div>
                                    <div className="textblock pad1">
                                        <img src="assets/dj2t.png" className="dj2t" alt="krafty kuts"/>
                                        <p>
                                            Renowned and respected worldwide for his highly technical turntablism, meticulously crafted DJ sets and party-punching productions, Krafty Kuts is in a league of his own. One of the key pioneers in the UK breakbeat and bass movement, Krafty Kuts has multiple awards to his name and has released music on the likes of Ministry Of Sound, OWSLA, Southern Fried, DMC, Fabric and many more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="image-row__image">
                                <div className="image-row__swap">
                                    <img src="assets/dj1.jpg" alt="A.Skillz"/>
                                    <img src="assets/DJ_Avatars.psdArtboard 1.jpg" alt="A.Skillz"/>
                                </div>
                                <div>
                                    <div className="textblock pad1">
                                        <img src="assets/dj1t.png" className="dj1t" alt="A.Skillz"/>
                                        <p>
                                            A.Skillz is a true party rocker behind the turntables, captivating crowds with his ability to embrace all aspects of music that he takes inspiration from and mash them up into a unique cocktail, infused with his own signature flavour of funk. With his DJ bookings taking him to the four corners of the world, he has appeared on stages big and small and no matter what venue or style, he always brings the house down…
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-events">
                <div className="section__inner">
                    <div className="textblock textblock--title pad3">
                        <h1>THE EVENT</h1>
                    </div>
                </div>
            </section>

            <section className="section-eventrow">
                <div className="section__inner">

                    <div className="pad1">
                        <div className="image-caption">
                            <div className="image-caption__caption">
                                <h3>What is Decentraland?</h3>
                                <p>
                                    A decentralized virtual social platform powered by the Ethereum blockchain. Within the Decentraland platform, users can create, experience, and monetize content and applications. Decentraland is unique in that it is owned and governed by the people who use the platform every day. Through the decentralized autonomous organization (DAO) users can submit proposals for owners of MANA (Decentraland currency) and LAND (Decentraland property) to vote on.
                                </p>
                                <a href="https://decentraland.org/" target="_blank">
                                    <div className="ms-button"><p>EXPLORE</p></div>
                                </a>
                            </div>
                            <div className="image-caption__image">
                                <div>
                                    <img src="assets/event.jpg" alt="What is Decentraland?"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-eventrow--reverse">
                <div className="section__inner">

                    <div className="pad1">
                        <div className="image-caption image-caption--reverse">
                            <div className="image-caption__caption">
                                <h3>What is the Metaverse Festival?</h3>
                                <p>
                                    The world’s first-ever multi-day music festival held in the metaverse. From October 21–24 2021, The Metaverse Festival featured over 80 artists, including Deadmau5, Alison Wonderland, Paris Hilton, Nina Nesbitt and dozens of other global artists across the musical spectrum.
                                </p>
                                <a href="https://themetaversefestival.io/" target="_blank">
                                    <div className="ms-button"><p>JUMP IN</p></div>
                                </a>
                            </div>
                            <div className="image-caption__image">
                                <div>
                                    <img src="assets/event2.jpg" alt="What is the Metaverse Festival?"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-eventrow">
                <div className="section__inner">

                    <div className="pad1">
                        <div className="image-caption">
                            <div className="image-caption__caption">
                                <h3>Digital Merch</h3>
                                <p>
                                    Metaverse Festival-goers were able to grab themselves exclusive, limited edition Monkey Shoulder x A.Skillz x Krafty Kuts digital wearables, available both in the festival and in the Decentraland marketplace. Caps, t-shirts and hoodies were available for free, with editions varying from 100 - 1,000 in rarity, all minted as NFT’s using Polygon, the Layer-2 scaling solution created to help bring mass adoption to the Ethereum platform. All 2,400 wearables were snapped up in 20 minutes and are now available on the resale market.
                                </p>
                                <a href="https://market.decentraland.org/browse?assetType=nft&section=wearables&isMap=false&isFullscreen=false&vendor=decentraland&page=1&sortBy=recently_listed&onlyOnSale=true&search=monkey+shoulder " target="_blank">
                                    <div className="ms-button"><p>BUY NOW</p></div>
                                </a>
                            </div>
                            <div className="image-caption__image">
                                <div>
                                    <img src="assets/GroupShot_V3.jpg" alt="The Afterparty"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-eventrow--reverse">
                <div className="section__inner">

                    <div className="pad1">
                        <div className="image-caption image-caption--reverse">
                            <div className="image-caption__caption">
                                <h3>The After-Party</h3>
                                <p>
                                    Monkey Shoulder Presents: the Metaverse Festival After-Party with DJ’s Krafty Kuts and A.Skillz mixing up a unique cocktail of electronic funk, breakbeats and hip hop to close out the festival in style. Performing as avatars at the Monkey Shoulder Hex Stage, where fans could grab themselves limited edition digital wearables. #MadeforMixing
                                </p>

                                <a href="#Anchorname">
                                    <div className="ms-button"><p>WATCH HIGHLIGHTS</p></div>
                                </a>

                            </div>
                            <div className="image-caption__image">
                                <div>
                                    <img src="assets/event4.jpg" alt="The shoot"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-eventrow">
                <div className="section__inner">

                    <div className="pad1">
                        <div className="image-caption">
                            <div className="image-caption__caption">
                                <h3>The Shoot</h3>
                                <p>
                                    The After-Party DJ set from Krafty Kuts and A.Skillz was shot using a combination of game engine and motion capture technology at Yahoo Creative Studios in Holborn, London. Custom Ready Player Me avatars were created and rigged for body and facial performance capture, rendered in real-time using Unreal Engine, that is also used to power movies and AAA games, like Fortnite.
                                </p>

                                <a href="#Anchorname">
                                    <div className="ms-button"><p>WATCH BTS</p></div>
                                </a>

                            </div>
                            <div className="image-caption__image">
                                <div>
                                    <img src="assets/event3.jpg" alt="Digital Merch"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="section-stripes">
                <div className="section__inner">
                    <a href="https://market.decentraland.org/browse?assetType=nft&section=wearables&isMap=false&isFullscreen=false&vendor=decentraland&page=1&sortBy=recently_listed&onlyOnSale=true&search=monkey+shoulder">
                    <img src="assets/bottom_image.png"/>
                    </a>
                </div>
            </section>
        </main>


            <footer className="footer ">
                <div className="wrap flex">
                    <div className="footer-top">
                        <div className="logo-container">
                            <a href="https://www.monkeyshoulder.com/" target="_blank">
                                <div className="ms-button"><p>To learn more about Monkey Shoulder</p></div>
                            </a>
                            <a className="logo" href="https://www.monkeyshoulder.com/" title="website logo">
                                <img className=" lazyloaded" data-src="/assets/logo-wordmark-strapline.svg"
                                     alt="MonkeyShoulder logo" height="100" width="500"
                                     src="./assets/logo-wordmark-strapline.svg" />
                                    <span className="sr-only">MonkeyShoulder Logo</span>
                            </a>
                        </div>
                        <div className="social-container">
                            <ul className="socials flex">
                                <li>
                                    <a target="_blank"
                                       href="https://www.facebook.com/search/top/?q=monkey%20Shoulder"
                                       title="Facebook" rel="noopener noreferrer">
                                        <i className="icon icon-facebook"></i>
                                        <span className="sr-only">Facebook</span>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com/monkeyshoulder3?lang=en"
                                       title="Twitter" rel="noopener noreferrer">
                                        <i className="icon icon-twitter"></i>
                                        <span className="sr-only">Twitter</span>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/monkeyshoulder/"
                                       title="Instagram" rel="noopener noreferrer">
                                        <i className="icon icon-instagram"></i>
                                        <span className="sr-only">Instagram</span>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.youtube.com/MonkeyShoulder3/" title="Youtube"
                                       rel="noopener noreferrer">
                                        <i className="icon icon-youtube"></i>
                                        <span className="sr-only">Youtube</span>
                                    </a>
                                </li>
                            </ul>
                            <div className="tags">
                                <p className="small">#MonkeyShoulder</p>
                                <p className="small">#MadeForMixing</p>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content">
                        <ul className="footer-menu">
                            <li className="footer-menu-item"><a href="https://www.monkeyshoulder.com/contact"
                                                                className="menu-text" title="Contact us link">Contact
                                us</a></li>
                            <li className="footer-menu-item"><a href="https://www.monkeyshoulder.com/privacy-policy"
                                                                className="menu-text"
                                                                title="Privacy &amp; Cookies Notice link">Privacy &amp; Cookies
                                Notice</a></li>
                            <li className="footer-menu-item"><a
                                href="https://www.monkeyshoulder.com/terms-and-conditions" className="menu-text"
                                title="Terms &amp; Conditions link">Terms &amp;
                                Conditions</a></li>
                        </ul>
                    </div>
                    <div className="footer-bottom">
                        <p className="xsmall">© Monkey Shoulder, 2021. All rights reserved.</p>
                        <div className="footer-bottom-right">
                            <h6>Don’t be a drunken monkey. Please drink responsibly.</h6>
                            <a className="be-drinkaware-container" href="http://www.drinkaware.co.uk/" target="_blank">
                                <img className=" lazyloaded" data-src="./assets/Drinkaware_BDA_logo_White_RGB.svg"
                                     alt="Be Drinkaware logo" src="assets/drinkaware.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}
export default Page_Main
