import React, {useEffect, useRef, useState} from "react";
import agegate, {getData} from "agegate";
import "./_page_agegate.scss";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import logo from "./monkey.dark.svg";
import {useHistory} from "react-router-dom";
import {isSafari, isIOS} from "react-device-detect";

const countries = getData();


function Page_AgeGate() {
    const history = useHistory();
    const [date, setDate] = useState("");
    const [country, setCountry] = useState(countries[0].code);
    const [notPermitted, setNotPermitted] = useState(false);
    const [days, setDays] = useState(Array.from(Array(1).keys()));
    const [years, setYears] = useState(generateArrayOfYears(100));
    const [leapYear, setLeapYear] = useState(false);

    const [dob_day, setDob_day] = useState(null);
    const [dob_month, setDob_month] = useState(null);
    const [dob_year, setDob_year] = useState(null);

    const [titleMonth, setTitleMonth] = useState("MONTH");
    const [titleDay, setTitleDay] = useState("DAY");
    const [titleYear, setTitleYear] = useState("YEAR");
    const [hasSubmit, setHasSubmit] = useState(false);
    const [completedAge, setCompletedAge] = useState(true);

    const checkboxRef = useRef()
    // const [days, setDays] = useState( [1,2,3,4,5]);

    useEffect(() => {
    }, [date])
    useEffect(() => {
        setDays(createArray(31));
        setTimeout(() => {
            selectElement('leaveCode', 'GB')
            sortSelect();
        }, 60);
    }, [])

    function selectElement(id, valueToSelect) {
        let element = document.getElementById(id);
        element.value = valueToSelect;
    }

    function createArray(n) {
        return Array.from(Array(n).keys())
    }

    const submitHandler = e => {
        e.preventDefault();
        setHasSubmit(true);
        if (date && country) {
            let result;
            if (!isIOS) {
                result = agegate(new Date(date), country);
            } else {
                result = agegate(new Date(date.replace(/-/g, "/")), country);
            }

            setNotPermitted(!result);
            if (result && checked) {
                localStorage.setItem("agegate", true)
                setTimeout(() => {
                    history.push("main")
                }, 1000)
            }
        }
        if (!dob_year || !dob_month || !dob_day) {
            setCompletedAge(false)
        } else {
            setCompletedAge(true)
        }
    };


    function onClick() {
        localStorage.setItem("agegate", true)
    }

    const handleSelectMonths = (e) => {
        switch (parseInt(e)) {
            case 9:
            case 4:
            case 6:
            case 11:
                setDays(createArray(30));
                break;
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                setDays(createArray(31));
                break;
            case 2:
                setDays(createArray(leapYear ? 28 : 29));
                break;
        }
        setDob_month(e);
        setTitleMonth(months[e - 1])
    }
    const handleSelectDays = (e) => {
        setDob_day(e)
        setTitleDay(e)
    }
    const handleSelectYears = (e) => {
        setDob_year(e)
        setTitleYear(e)
    }

    const fr = useRef(true);
    useEffect(() => {
        let returnDate = `${dob_year}-${dob_month}-${dob_day}`
        if (!dob_year || !dob_month || !dob_day) {
            returnDate = null;
        } else {

        }
        setDate(returnDate)
    }, [dob_year, dob_month, dob_day])

    function onClick() {

    }

    const [checked, setChecked] = useState(false);

    useEffect(() => {
    }, [checked])


    return (
        <>


            <div className="bg"></div>

            <div className="ag_container">
                <div className="ag_modal">
                    <img src={logo} className="ag_logo"/>

                    <h2>YOU MUST BE OVER THE LEGAL DRINKING AGE IN YOUR LOCATION TO ENTER THIS WEBSITE.</h2>

                    <h4>WHAT'S YOUR DATE OF BIRTH?</h4>
                    <div className="ag_formrow">

                        <div className="ag_formrow__el">
                            <DropdownButton
                                title={titleMonth}
                                id="dropdown-menu-align-right"
                                onSelect={handleSelectMonths}
                            >
                                <Dropdown.Item eventKey="1">January</Dropdown.Item>
                                <Dropdown.Item eventKey="2">February</Dropdown.Item>
                                <Dropdown.Item eventKey="3">March</Dropdown.Item>
                                <Dropdown.Item eventKey="4">April</Dropdown.Item>
                                <Dropdown.Item eventKey="5">May</Dropdown.Item>
                                <Dropdown.Item eventKey="6">June</Dropdown.Item>
                                <Dropdown.Item eventKey="7">July</Dropdown.Item>
                                <Dropdown.Item eventKey="8">August</Dropdown.Item>
                                <Dropdown.Item eventKey="9">September</Dropdown.Item>
                                <Dropdown.Item eventKey="10">October</Dropdown.Item>
                                <Dropdown.Item eventKey="11">November</Dropdown.Item>
                                <Dropdown.Item eventKey="12">December</Dropdown.Item>

                            </DropdownButton>
                        </div>

                        <div className="ag_formrow__el">
                            <DropdownButton
                                title={titleDay}
                                id="dropdown-menu-align-right"
                                onSelect={handleSelectDays}
                            >
                                {days.map((day, index) => (
                                    <Dropdown.Item eventKey={day + 1} key={index}>{day + 1}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                        <div className="ag_formrow__el">
                            <DropdownButton

                                title={titleYear}
                                id="dropdown-menu-align-right"
                                onSelect={handleSelectYears}
                            >
                                {years.map((year, index) => (
                                    <Dropdown.Item eventKey={year} key={index}>{year}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                    </div>
                    <div>
                        <div className="error-age" style={{display: completedAge ? "none" : "block"}}>
                            Please complete all fields
                        </div>
                    </div>


                    <h4>WHERE ARE YOU?</h4>
                    <div className="ag_formrow">
                        <div className="ag_formrow__el ag_formrow__el--full">
                            <div className="select__wrapper">
                            <select
                                id="leaveCode" name="leaveCode"
                                onChange={e => setCountry(e.target.value)} className="select">
                                {countries.map(({code, name}) => (
                                    <option key={name} value={code}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                            </div>

                        </div>
                    </div>

                    <div className="ag_result">
                        <p>
                            {notPermitted ? "The laws of your country do not permit you to visit this site." : ""}
                        </p>
                    </div>

                    <form onSubmit={submitHandler} noValidate>
                        <div className="input-checkbox">
                            <input ref={checkboxRef} id="ldaCookie" type="checkbox" name="cookie" required
                                   className="lda-cookie valid"
                                   defaultChecked={checked}
                                   onChange={() => setChecked(!checked)}/>
                            <label htmlFor="ldaCookie">Use a cookie to remember me.</label>

                            <div style={{display: hasSubmit ? "block" : "none"}}>
                                <div className="error" style={{display: checked ? "none" : "block"}}>This field is
                                    required
                                </div>
                            </div>
                        </div>


                        <div className="terms">
                            <p className="small notice-text">You must have cookies enabled to use this website. For more
                                information on using and deleting cookies, visit <a href='www.aboutcookies.org'
                                                                                    target='_blank'>www.aboutcookies.org</a>.</p>

                            <p className="small notice-text">Please take a look at our <a
                                href="https://www.monkeyshoulder.com/privacy?lb=t">Privacy
                                Policy</a> and our <a
                                href="https://www.monkeyshoulder.com/terms-conditions?lb=t">Terms &amp; Conditions</a>
                            </p>

                        </div>


                        <button className="enterbutton" type="submit" onClick={onClick}>Submit</button>

                    </form>

                    <div className="terms">
                        <p className="small notice-text">
                            <a href='https://www.responsibility.org' target='_blank'>www.responsibility.org</a> | <b>Please drink Monkey Shoulder responsibly.</b>
                        </p>
                        <p className="small notice-text">
                            WILLIAM GRANT & SONS LIMITED. Registered in Scotland.<br/>
                            Company number: SC131772.<br/>
                            Registered office: William Grant & Sons LTD, Scotland, ML4 3AN. VAT Number: 554690029
                        </p>
                        <p className="small notice-text">
                            ©2021 William Grant & Sons Limited, Dufftown, Speyside.
                        </p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Page_AgeGate

function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

function generateArrayOfYears(n) {
    var max = new Date().getFullYear()
    var min = max - n
    var years = []

    for (var i = max; i >= min; i--) {
        years.push(i)
    }
    return years
}

const months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER"
]

function sortSelect() {
    var select = document.getElementById('leaveCode');
    var sorted = Array.prototype.slice.call(select.options).sort(function (a, b) {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    });

    for (var i = 0; i < sorted.length; i++) {
        select.add(sorted[i]);
    }
}
