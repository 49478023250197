import logo from './logo.svg';
import './App.scss';
import {
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import Page_AgeGate from "./pages/Page_AgeGate/Page_AgeGate";
import Page_Main from "./pages/Page_Main/Page_Main";
import {useEffect} from "react";
import Page_Home from "./pages/Page_Home/Page_Home";

function App() {
    const history = useHistory();
    useEffect(()=> {
        const ls_agegate = localStorage.getItem('agegate');
        if (ls_agegate) {
            history.push("main")
        } else {
            history.push("agegate")
        }
    }, [])

  return (
    <div className="App">

      <Switch>
        <Route path="/" component={Page_Home} exact/>
        <Route path="/agegate" component={Page_AgeGate} exact/>
        <Route path="/main" component={Page_Main} exact/>
      </Switch>
    </div>
  );
}

export default App;
